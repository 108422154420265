// CALENDAR SINGLE DATE PICKER: 

$('#start-study').daterangepicker({
    autoUpdateInput: false,
    singleDatePicker: true,
    "opens": "center",
    "buttonClasses": "btn",
    "applyButtonClasses": "blue-btn",
    "cancelClass": "blue-border-btn",
    "alwaysShowCalendars": true,
}, function (start) {
    $('#start-study').val(start.format('MM/DD/YYYY'));
});

//end-study date is auto detect


// CALENDAR DATE RANGE PICKERS: edit start/end of study year MM/DD/YYYY dates outside this range are unselectable and crossed out on the calendar.

$('#holidays-w1').daterangepicker({
    autoUpdateInput: false,
    "maxSpan": {
        "days": 7 //limits picker to 1 week or less
    },
    "opens": "center",
    "buttonClasses": "btn",
    "applyButtonClasses": "blue-btn",
    "cancelClass": "blue-border-btn",
    "alwaysShowCalendars": true,
    "minDate": "02/21/2020", //start of study year
    "maxDate": "03/21/2121", //end of study year
}, function (start, end) {
    $('#holidays-w1').val(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
});

$('#holidays-w2').daterangepicker({
    autoUpdateInput: false,
    "maxSpan": {
        "days": 7
    },
    "opens": "center",
    "buttonClasses": "btn",
    "applyButtonClasses": "blue-btn",
    "cancelClass": "blue-border-btn",
    "alwaysShowCalendars": true,
    "minDate": "02/21/2020",
    "maxDate": "03/21/2121",
}, function (start, end) {
    $('#holidays-w2').val(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
});

$('#holidays-w3').daterangepicker({
    autoUpdateInput: false,
    "maxSpan": {
        "days": 7
    },
    "opens": "center",
    "buttonClasses": "btn",
    "applyButtonClasses": "blue-btn",
    "cancelClass": "blue-border-btn",
    "alwaysShowCalendars": true,
    "minDate": "02/21/2020", //start of study year
    "maxDate": "03/21/2121", //end of study year
}, function (start, end) {
    $('#holidays-w3').val(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
});

$('#holidays-w4').daterangepicker({
    autoUpdateInput: false,
    "maxSpan": {
        "days": 7 //limits picker to 1 week or less
    },
    "opens": "center",
    "buttonClasses": "btn",
    "applyButtonClasses": "blue-btn",
    "cancelClass": "blue-border-btn",
    "alwaysShowCalendars": true,
    "minDate": "02/21/2020", //start of study year
    "maxDate": "03/21/2121", //end of study year
}, function (start, end) {
    $('#holidays-w4').val(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
});

$('#fellowship-w1').daterangepicker({
    autoUpdateInput: false,
    "maxSpan": {
        "days": 7 //limits picker to 1 week or less
    },
    "opens": "center",
    "buttonClasses": "btn",
    "applyButtonClasses": "blue-btn",
    "cancelClass": "blue-border-btn",
    "alwaysShowCalendars": true,
    "minDate": "02/21/2020", //start of study year
    "maxDate": "03/21/2121", //end of study year
}, function (start, end) {
    $('#fellowship-w1').val(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
});

$('#fellowship-w2').daterangepicker({
    autoUpdateInput: false,
    "maxSpan": {
        "days": 7 //limits picker to 1 week or less
    },
    "opens": "center",
    "buttonClasses": "btn",
    "applyButtonClasses": "blue-btn",
    "cancelClass": "blue-border-btn",
    "alwaysShowCalendars": true,
    "minDate": "02/21/2020", //start of study year
    "maxDate": "03/21/2121", //end of study year
}, function (start, end) {
    $('#fellowship-w2').val(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
});

$('#fellowship-w3').daterangepicker({
    autoUpdateInput: false,
    "maxSpan": {
        "days": 7 //limits picker to 1 week or less                                                                                                       
    },
    "opens": "center",
    "buttonClasses": "btn",
    "applyButtonClasses": "blue-btn",
    "cancelClass": "blue-border-btn",
    "alwaysShowCalendars": true,
    "minDate": "02/21/2020", //start of study year
    "maxDate": "03/21/2121", //end of study year
}, function (start, end) {
    $('#fellowship-w3').val(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
});
